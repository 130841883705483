<template>
  <div class="d-flex justify-center mb-6">
    <v-card elevation="0" style="width: 98%;">
      <!-- Cards -->
      <v-container fluid>
        <v-toolbar flat class="mb-4">
          <v-text-field
            style="width: 100%; max-width: 450px"
            v-model="searchTerm"
            ref="searchImage"
            append-icon="search"
            label=""
            :disabled="loading"
            single-line
            clearable
            hide-details
            @click:clear="viewAll"
            @click:append="() => onChange(searchTerm)"
            @change="(term) => onChange(term)"
          ></v-text-field>

          <v-spacer></v-spacer>
          <v-select
            :disabled="loading"
            @input="updateSortAndOrder"
            style="height: 40px; min-height: 44px; max-width: 105px;"
            dense
            label="الترتيب حسب"
            v-model="sortByDropdown"
            :items="sortDropdown"
            filled
            class="ml-4"
            hide-selected
            return-object
            outlined
          ></v-select>
          <v-select
            :disabled="loading"
            @input="updateSortAndOrder"
            style="height: 40px;min-height: 44px;max-width: 105px;"
            dense
            class="ml-4"
            label="التسلسل"
            v-model="orderDropdown"
            :items="[
              { text: 'تصاعدي', field: 'ASC' },
              { text: 'تنازلي', field: 'DESC' },
            ]"
            filled
            hide-selected
            return-object
            outlined
          ></v-select>
        </v-toolbar>
        <div
          v-if="loading"
          class="d-flex h-100 justify-center align-items-center"
          style="min-height:50vh;"
        >
          <v-progress-circular
            indeterminate
            color="black"
            size="50"
          ></v-progress-circular>
        </div>
        <v-data-iterator
          v-else
          :items="getSearchResultImages"
          hide-default-footer
          :items-per-page="-1"
        >
          <template v-slot:default="props">
            <v-row>
              <v-col
                v-for="image in props.items"
                :key="image.id"
                cols="12"
                sm="4"
                md="4"
                lg="4"
              >
                <v-card>
                  <div class="image-image-container">
                    <img
                      class="h-20 image-image"
                      :src="
                        apiUrl +
                          '/entities/images/raw/' +
                          image.fileName +
                          '/' +
                          imagesSize
                      "
                    />
                  </div>
                  <v-list dense>
                    <v-list-item>
                      <v-list-item-content class="font-weight-bold">{{
                        properties[1].text
                      }}</v-list-item-content>
                      <v-list-item-content class="align-end">
                        {{ image.id }}
                      </v-list-item-content>
                    </v-list-item>

                    <v-list-item>
                      <v-list-item-content class="font-weight-bold">{{
                        properties[2].text
                      }}</v-list-item-content>
                      <v-list-item-content class="align-end">
                        {{ image.date }}
                      </v-list-item-content>
                    </v-list-item>

                    <v-list-item>
                      <v-list-item-content class="font-weight-bold">{{
                        properties[3].text
                      }}</v-list-item-content>
                      <v-list-item-content class="align-end">
                        {{ image.safirUniqueId }}
                      </v-list-item-content>
                    </v-list-item>

                    <v-list-item style="min-height: 68.8px; padding-left: 2px;">
                      <v-list-item-content
                        class="font-weight-bold"
                        style="padding-right: 0px; max-width: 178.25px;"
                        >{{ properties[4].text }}</v-list-item-content
                      >
                      <v-list-item-content>
                        <div v-if="image.caption" style="text-align: right">
                          {{ truncate(image.caption, 60) }}
                        </div>
                        <span style="text-align: right" v-else>
                          -
                        </span>
                      </v-list-item-content>
                    </v-list-item>

                    <v-list-item style="min-height: 68.8px; padding-left: 2px;">
                      <v-list-item-content
                        class="font-weight-bold"
                        style="padding-right: 0px; max-width: 178.25px;"
                        >{{ properties[5].text }}</v-list-item-content
                      >
                      <v-list-item-content>
                        <div v-if="image.description" style="text-align: right">
                          {{ truncate(image.description, 60) }}
                        </div>
                        <span style="text-align: right" v-else>
                          -
                        </span>
                      </v-list-item-content>
                    </v-list-item>

                    <v-list-item>
                      <v-list-item-content class="font-weight-bold">{{
                        properties[6].text
                      }}</v-list-item-content>
                      <v-list-item-content class="align-end">
                        {{ image.photographerName }}
                      </v-list-item-content>
                    </v-list-item>

                    <v-list-item>
                      <v-list-item-content class="font-weight-bold">{{
                        properties[7].text
                      }}</v-list-item-content>
                      <v-list-item-content class="align-end">
                        {{ image.sourceName }}
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>

                  <v-divider
                    style="margin-bottom: 2px; margin-top: 0px;"
                  ></v-divider>

                  <div class="d-flex flex-direction-row justify-space-around">
                    <v-btn plain @click="goToEdit(image.id)">
                      <v-icon>
                        mdi-archive-edit
                      </v-icon>
                    </v-btn>
                    <v-btn plain @click="deleteImageSingle(image.id)">
                      <v-icon>
                        mdi-delete
                      </v-icon>
                    </v-btn>
                  </div>
                </v-card>
              </v-col>
            </v-row>
          </template>
        </v-data-iterator>
      </v-container>

      <div class="d-flex justify-content-center w-100 mt-4">
        <v-pagination
          v-model="currentPage"
          color="#f29100ff"
          :length="totalPages"
          :total-visible="5"
          @input="handlePageChange"
        >
        </v-pagination>
        <v-select
          :items="[6, 18, 24, 54, 75]"
          v-model="query.pageSize"
          dense
          flat
          :loading="loading"
          label="عدد"
          class="mr-2"
          @change="updateSortAndOrder"
          style="max-width: 55px; max-height: 50px;"
        ></v-select>
      </div>
      <div v-if="!loading" class="d-flex justify-content-center mt-4">
        {{ currentPageCountStart }} -
        {{ finalNumberText }}
        من أصل {{ getImagesGlobalCount }}
      </div>
    </v-card>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import imageConfig from "@helpers/imageStructure";
import getSortableField from "@helpers/getSortableField";
import config from "@/helpers/config";

export default {
  data() {
    return {
      title: imageConfig.title,
      properties: imageConfig.properties,
      singleSelect: false,
      searchTerm: "",
      selected: [],
      loading: false,
      sortByDropdown: { text: "#UID", field: "_id" },
      orderDropdown: { text: "تصاعدي", field: "ASC" },
      sortDropdown: imageConfig.getSortDropdown(),
      query: {
        order: "ASC",
        sortBy: "id",
        pageSize: 6,
        pageNumber: 1,
      },
      currentPageCountStart: 1,
      currentPageCountEnd: 1,
      currentPage: 1,
      totalPages: 0,
      apiUrl: config.apiUrl,
      imagesSize: "200",
    };
  },
  methods: {
    ...mapActions("imagesBackend", [
      "fetchSearchResultImages",
      "fetchAllImages",
      "deleteImage",
    ]),
    ...mapMutations("notification", { notifyVuex: "notify" }),
    ...mapMutations("imagesBackend", ["SET_NUMBER_OF_ROWS"]),
    setLoading(status) {
      this.loading = status;
      this.updatePagination();
    },
    goToEdit(id) {
      if (!this.loading) {
        this.$router.push({ name: "ImageEdit", params: { id } });
      }
    },
    viewAll() {
      this.setQuery();
      this.fetchAllImages({
        query: this.query,
        loadingFunction: this.setLoading,
      });
      this.setLoading(true);
      this.searchTerm = "";
    },
    notify(message) {
      this.notifyVuex({ show: true, message });
    },
    updatePagination() {
      this.totalPages = Math.ceil(
        this.getImagesGlobalCount / this.query.pageSize
      );
      this.currentPageCountStart =
        parseInt(this.query.pageSize) * parseInt(this.query.pageNumber) -
        parseInt(this.query.pageSize) +
        1;
      this.currentPageCountEnd =
        parseInt(this.query.pageSize) * parseInt(this.query.pageNumber) -
        parseInt(this.query.pageSize) +
        parseInt(this.query.pageSize);
    },
    search(term) {
      this.setQuery();
      if (term) {
        this.fetchSearchResultImages({
          term,
          query: { ...this.query },
          loadingFunction: this.setLoading,
        });
      } else {
        this.viewAll();
      }
    },
    handlePageChange(value) {
      this.currentPage = value;
      this.query.pageNumber = value;

      this.updateSortAndOrder();
      window.scrollTo(0, 0);
    },
    deleteImageSingle(imageId) {
      this.$confirm({
        message: `هل أنت متأكد(ة)؟ لا يمكن التراجع عن هذه الخطوة`,
        button: {
          no: "لا",
          yes: "نعم",
        },
        callback: (confirm) => {
          if (confirm) {
            this.deleteImage({
              imageId,
              loadingFunction: this.setLoading,
              inCaseOfError: this.notify,
            });
          }
        },
      });
    },
    handleClick(row) {
      if (!this.loading) {
        this.$router.push({ name: "ImageEdit", params: { id: row.id } });
      }
    },
    goToCreate() {
      this.$router.push({ name: "ImageCreate" });
    },
    onChange(term) {
      this.search(term);
      this.$refs.searchImage.blur();
    },
    setQuery() {
      this.query.sortBy = getSortableField(this.sortByDropdown.field);
      this.query.order = this.orderDropdown.field;
    },
    updateSortAndOrder() {
      this.SET_NUMBER_OF_ROWS(this.query.pageSize);
      if (this.getSearchTermImage) {
        this.search(this.searchTerm);
      } else {
        this.viewAll();
      }
    },
    truncate(str, n) {
      return str.length > n ? str.substr(0, n - 1) + "..." : str;
    },
  },
  computed: {
    ...mapGetters("imagesBackend", [
      "getSearchResultImages",
      "getSearchTermImage",
      "getImagesGlobalCount",
      "getImagesLocalCount",
      "getNumberOfRows",
    ]),
    finalNumberText: function() {
      if (this.currentPageCountEnd > this.getImagesGlobalCount) {
        return this.getImagesGlobalCount;
      } else {
        return this.currentPageCountEnd;
      }
    },
  },
  mounted() {
    this.currentPage = 1;
    this.query.pageSize = this.getNumberOfRows;
    if (!this.getSearchTermImage) {
      this.viewAll();
    }
    this.searchTerm = this.getSearchTermImage;
  },
};
</script>

<style>
th.text-right > span {
  font-size: 19px;
}

td.text-right {
  font-size: 16px !important;
}

.v-label {
  margin-top: 6px;
}

.v-select__slot {
  height: 44px;
}

div.v-select__slot:nth-child(1) {
  max-height: 35px;
  flex-direction: row-reverse;
}

div.v-select__selections:nth-child(1) {
  max-height: 30px;
}

div.v-select__slot:nth-child(1) > div:nth-child(2) {
  align-self: center;
}

div.v-select__slot:nth-child(1) > label:nth-child(1) {
  right: 8px !important;
}

.image-image-container {
  object-fit: cover;
}

.image-image {
  object-fit: cover;
  max-height: 180px;
  width: 100%;
}
</style>
