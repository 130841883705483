export default {
  title: "اللغات",
  entityName: "image",
  properties: [
    {
      text: "الصورة",
      value: "fileName",
      align: "right",
      field: "_fileName",
      sortable: false,
    },
    {
      text: "#UID",
      value: "id",
      align: "right",
      field: "_id",
      sortable: false,
    },
    {
      text: "التاريخ",
      value: "date",
      align: "right",
      field: "_date",
      sortable: false,
    },
    {
      text: "السفير فريد #UID",
      value: "safirUniqueId",
      align: "right",
      field: "_safirUniqueId",
      sortable: false,
    },
    {
      text: "العنوان الفرعي",
      value: "caption",
      align: "right",
      field: "_caption",
      sortable: false,
    },
    {
      text: "وصف",
      value: "description",
      align: "right",
      field: "_description",
      sortable: false,
    },
    {
      text: "اسم المصور",
      value: "photographerName",
      align: "right",
      field: "_photographerName",
      sortable: false,
      restrictSort: true,
    },
    {
      text: "اسم المصدر",
      value: "sourceName",
      align: "right",
      field: "_sourceName",
      sortable: false,
      restrictSort: true,
    },
  ],
  getHeader() {
    let newArr = [];

    this.properties.forEach(({ text, value, align, sortable }) => {
      newArr.push({ text, value, align, sortable });
    });

    return newArr;
  },
  getSortDropdown() {
    let newArr = [];

    this.properties.forEach(({ text, field, restrictSort }) => {
      if (!restrictSort) {
        newArr.push({ text, field });
      }
    });

    return newArr;
  },
};
